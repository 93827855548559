import React from 'react'
import './hero.css'
// import { NavLink } from 'react-router-dom'

const Hero = () => {
  return (
    <>
    <div className="container-fluid d-md-none d-block py-5">

    </div>
      <div className="container-fluid px-0 sec-home-hero">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5 hero-section">
              <span className='heading-one'>SPREAD AWARENESS</span> <br /> <span className='heading-two'>OF YOUR BRAND</span><br /><span className='heading-three'>in the most economical way</span>
              <div className='mt-5 my-3'>
                <a href='/about' className='btn-hero1'>KNOW HOW</a>
                <a href="/contact" className='btn-hero2 ms-3'>REACH US</a>
              </div>
            </div>
            <div className="col-md-7 bg-hero text-center">
              <img src={process.env.PUBLIC_URL + '/assets/img/home/hero/heroImg.png'} alt="" className='hero-img' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Hero