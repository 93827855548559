import React from 'react'
import './about.css'

const About = () => {
    return (
        <>
            <div className="container-fluid section-home-about">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 px-md-5">
                            <img src={process.env.PUBLIC_URL + '/assets/img/home/about/About_Us.png' } alt="" className='img-about-home'/>
                        </div>
                        <div className="col-md-6">
                            <p className='about-head mt-3 mb-0'>ABOUT THE SCALE STORY</p>
                            <p className='about-para mb-0'>At The Scale Story, we are a team of experienced professionals passionate about offering affordable and sustainable OOH advertising solutions for businesses. We believe in creating value-driven brand stories and promoting them through our innovative TV machines, which not only save costs but also help save paper.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid section-home-about">
            <div className="row">
                        <div className="col-md-12 text-end">
                            <img src={process.env.PUBLIC_URL + '/assets/img/home/about/partner.png'} alt="" className='img-about-icon' />
                        </div>
                    </div>
            </div>

        </>
    )
}

export default About