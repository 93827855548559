import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css'
import Home from './pages/home/Home'
import Header from './components/common/header/Header'
import AboutPage from './pages/about/AboutPage'
import Footer from './components/common/footer/Footer'
import Services from './pages/services/Services'
import ContactUs from './pages/contactus/ContactUs'
import PlaylistVideo from './pages/playlist/PlaylistVideo'
import ScrollToTop from './ScrollToTop'

const App = () => {
  return (
    <>
    <Router>
    <ScrollToTop/>
    <Header/>
    <Routes>
      <Route>
        <Route path ='/' element = {<Home/>} />
        <Route path = '/about' element = {<AboutPage/>} />
        <Route path = '/service' element = {<Services/>} />
        <Route path = '/contact' element = {<ContactUs/>} />
        <Route path='/playlist' element = {<PlaylistVideo/>} />
      </Route>
    </Routes>
    <Footer/> 
    </Router>
    </>
  )
}

export default App